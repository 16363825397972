@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
	background:  radial-gradient(#031B29CC,#B7B7B7, #031B29, #B7B7B7);;
	background-size: 500% 400%;
	animation: gradient 15s ease infinite;
  backdrop-filter: "blur(20px)";
}

body {
  overflow: auto;
  font-family: "Inter var", sans-serif;
}

p {
  margin: 0;
  padding: 0;
}

a {
  padding-right: 10px;
  cursor: pointer;
  pointer-events: all;
  color: black;
  text-decoration: none; /* no underline */
}

@media screen and (max-width: 568px) {
  .full {
    visibility: hidden;
    display: none;
  }
}
