body {
  background: radial-gradient(#031b29cc, #b7b7b7, #031b29, #b7b7b7);
  background-size: 500% 400%;
  animation: gradient 15s ease infinite;
  backdrop-filter: "blur(20px)";
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
    background: linear-gradient(25deg);
  }
  50% {
    background-position: 100% 50%;
    background: linear-gradient(90deg);
  }
  100% {
    background-position: 0% 50%;
    background: linear-gradient(-45deg);
  }
}

@keyframes start {
  from {
    width: 100%;
  }
  to {
    width: 50%;
  }
}

/* The element to apply the animation to */
.start {
  animation-name: start;
  animation-duration: 0.6s;
}

@keyframes start2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* The element to apply the animation to */
.start2 {
  animation-name: start2;
  animation-duration: 0.6s;
}
.ball {
  max-width: 50%;
  max-height: 220px;
}

.mainDiv {
  height: 60%;
}

.media {
  width: 600px;
}
@media only screen and (max-width: 1200px) {
  .mainDiv {
    height: auto;
    min-height: 60vh;
  }
  .ball {
    max-height: 250px;
  }
}

@media only screen and (min-width: 1710px) {
  .mainDiv {
    height: auto;
    min-height: 60vh;
  }
  .ball {
    max-height: 250px;
  }
}

@media only screen and (max-width: 990px) {
  .media {
    width: 400px;
  }
  body {
    background: linear-gradient(-45deg, #031b29cc, #b7b7b7, #031b29);
    background-size: 500% 500%;
    animation: gradient 5s ease infinite;
    backdrop-filter: "blur(20px)";
  }
}

@media only screen and (max-width: 600px) {
  .media {
    width: 300px;
  }
}
